<template>
  <v-app id="inspire">
    <v-main>
      <v-container
          class="fill-height"
          fluid
      >
        <v-row
            align="center"
            justify="center"
        >
          <v-col
              cols="12"
              sm="8"
              md="4"
          >
            <v-card class="elevation-12">
              <v-toolbar
                  color="primary"
                  dark
                  flat
              >
                <v-toolbar-title>Login form</v-toolbar-title>
              </v-toolbar>
              <v-card-text>
                <v-alert type="error" class="" v-if="errorMsg">
                  {{ errorMsg }}
                </v-alert>
                <v-form
                    ref="form"
                    v-model="valid"
                >
                  <v-text-field
                      label="Email"
                      name="email"
                      prepend-icon="email"
                      type="text"
                      v-model="credential.email"
                      :rules="[rules.required, rules.email]"
                  ></v-text-field>

                  <v-text-field
                      id="password"
                      label="Password"
                      name="password"
                      prepend-icon="lock"
                      type="password"
                      v-model="credential.password"
                      :rules="passwordRules"
                  ></v-text-field>
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-btn color="primary" text @click="doForgotPassword()">Lupa password ?</v-btn>
                <v-spacer></v-spacer>
                <v-btn
                    color="primary"
                    :disabled="!valid"
                    @click="login">
                  Login
                </v-btn>

              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>

    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64">
      </v-progress-circular>
      <v-spacer></v-spacer>
      <v-btn @click="overlay=false" text class="mt-2 white--text">Cancel</v-btn>
    </v-overlay>
  </v-app>
</template>

<script>
import { login } from '../../../store/helpers/auth';

export default {
  name: "Login",
  data() {
    return {
      errorMsg: null,
      overlay : false,
      valid: true,
      passwordRules: [
        v => !!v || 'Password is required',
        v => (v && v.length >= 6) || 'Password must be less than 6 characters',
      ],
      rules: {
        required: value => !!value || "Required",
        email: value => {
          const patern = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/
          return patern.test(value) || "Invalid Email"
        },
      },
      credential: {
        email: null,
        password: null,
      },
    };
  },
  methods: {
    login: async function() {
      this.overlay = this.$store.getters.isLoading;

      await this.$store.dispatch('login')
      try {
        let response = await login(this.credential);
        this.overlay = this.$store.getters.isLoading;
        if(!response.tokenData){
          this.errorMsg = response
          return false
        }
        this.$store.commit("loginSuccess", response)
        await this.$router.push({path: '/'})
      } catch (e) {
        this.$store.commit("loginFailed", {error: e})
        this.overlay = this.$store.getters.isLoading;
        this.errorMsg = this.$store.getters.authError
      }
    },
    doForgotPassword : function (){
      this.$router.push({path: '/forgot'})
    }
  }
}
</script>

<style scoped>

</style>
